import { TTgbNativedescGeneralContext } from '@cian/adfox-templates';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { useEffect } from 'react';

import { TgbNativedescGeneral, TgbNativedescGeneralProps } from './TgbNativedescGeneral';
import { getCurrentJsonQuery } from '../../../../../selectors/location';
import { fetchNewbuildingsInfoForTgb } from '../../../../../actions/infoForTgb/fetchNewbuildingsInfoForTgb';
import { selectNewbuidingsInfoForTgb } from '../../../../../selectors/infoForTgb/selectNewbuidingsInfoForTgb';
import { TTypedThunkDispatch } from '../../../../../types/thunk';
import { jsonRegionIdToTransportType } from '../../../../../utils/geo/jsonRegionIdToTransportType';
import {
  EDealType,
  EObjectType,
} from '../../../../../repositories/ad-banner/entities/get_banner_enrichments_v1/GetBannerEnrichmentsV1Request';

type TgbNativedescGeneralContainerProps = {
  context: TTgbNativedescGeneralContext;
};

export const TgbNativedescGeneralContainer: React.FC<TgbNativedescGeneralContainerProps> = props => {
  const { context } = props;

  const {
    annotation,
    image,
    url,
    metroDistance,
    metroColor,
    metro,
    description,
    title,
    location,
    button,
    label,
    logoImageUrl,
    builderName,
    newBuildingId,
    newBuildingIdIsUsed,
    objectId,
    objectType,
    dealType,
    useServerEnrichment,
  } = context;

  const dispatch = useDispatch<TTypedThunkDispatch>();

  const newbuidingsInfoForTgb = useSelector(selectNewbuidingsInfoForTgb);

  const jsonQuery = useSelector(getCurrentJsonQuery);

  /**
   * @description Только для значений newBuildingIdIsUsed === '1' и useServerEnrichment === '1' делаем запрос на бекенд для обогащения данных
   */
  const isEnrichmentFromBackendEnabled = newBuildingIdIsUsed === '1' || useServerEnrichment === '1';

  const newBuildingIdInt = newBuildingId ? parseInt(newBuildingId, 10) : null;
  const objectIdInt = objectId ? parseInt(objectId, 10) : newBuildingIdInt;

  const newbuidingInfoForTgb =
    objectIdInt && isEnrichmentFromBackendEnabled ? newbuidingsInfoForTgb[objectIdInt] : null;

  useEffect(() => {
    if (isEnrichmentFromBackendEnabled && objectIdInt && !newbuidingInfoForTgb) {
      dispatch(
        fetchNewbuildingsInfoForTgb({
          objectId: objectIdInt,
          dealType: dealType as EDealType,
          objectType: objectType as EObjectType,
        }),
      );
    }
    /**
     * @description Тут смотрим на изменение objectIdInt, изменение остальных данных не должно триггерить запрос
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectIdInt]);

  const tgbNativedescGeneralProps = ((): TgbNativedescGeneralProps => {
    if (newbuidingInfoForTgb) {
      return {
        logoImageUrl: newbuidingInfoForTgb.publisher.logo.url,
        url,
        transports: newbuidingInfoForTgb.transports,
        label,
        button,
        image,
        annotation,
        builderName: newbuidingInfoForTgb.publisher.name,
        title,
        address: newbuidingInfoForTgb.address,
        description,
      };
    }

    const transports: TgbNativedescGeneralProps['transports'] = [];

    if (metro) {
      const iconType = jsonRegionIdToTransportType(jsonQuery);

      transports.push({
        iconColor: metroColor,
        iconType,
        id: 1,
        name: metro,
        remoteness: metroDistance,
      });
    }

    return {
      logoImageUrl,
      url,
      transports,
      label,
      button,
      image,
      annotation,
      builderName,
      title,
      address: location,
      description,
    };
  })();

  return <TgbNativedescGeneral {...tgbNativedescGeneralProps} />;
};
