import { EInfoForTgbActions } from '../../actions/infoForTgb/actionTypes';
import { FetchInfoForTgbSuccess } from '../../actions/infoForTgb/setNewbuildingsInfoForTgb';
import { IBannerEnrichments } from '../../repositories/ad-banner/entities/get_banner_enrichments_v1/BannerEnrichments';

export type InfoForTgbState = {
  jkInfoForTgbCollection: Record<number, IBannerEnrichments>;
};

const initialState: InfoForTgbState = {
  jkInfoForTgbCollection: {},
};

export function infoForTgbReducer(state = initialState, action: FetchInfoForTgbSuccess): InfoForTgbState {
  switch (action.type) {
    case EInfoForTgbActions.fetchNewbuildingsInfoForTgbSuccess:
      return {
        ...state,
        jkInfoForTgbCollection: action.payload,
      };
    default:
      return state;
  }
}
