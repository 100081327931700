import { TTypedThunkDispatch } from '../../types/thunk';
import { IApplicationState } from '../../types/redux';
import { IApplicationContext } from '../../types/applicationContext';
import { fetchGetNewbuildingsForSerpService } from '../../services/fetchGetNewbuildingsForSerpService';
import { noop } from '@cian/newbuilding-utils';
import { setNewbuildingsInfoForTgb } from './setNewbuildingsInfoForTgb';
import { selectNewbuidingsInfoForTgb } from '../../selectors/infoForTgb/selectNewbuidingsInfoForTgb';
import { IBannerEnrichments } from '../../repositories/ad-banner/entities/get_banner_enrichments_v1/BannerEnrichments';
import { TGetBannerEnrichmentsRequest } from '../../repositories/ad-banner/v1/get-banner-enrichments';

export function fetchNewbuildingsInfoForTgb(parameters: TGetBannerEnrichmentsRequest) {
  return (
    dispatch: TTypedThunkDispatch,
    getState: () => IApplicationState,
    applicationContext: IApplicationContext,
  ) => {
    return fetchGetNewbuildingsForSerpService(applicationContext, parameters).then(
      data => {
        const state = getState();

        const newbuildingInfoForTgb = selectNewbuidingsInfoForTgb(state);

        if (!data.enrichments) {
          return;
        }

        const payload: Record<number, IBannerEnrichments> = {
          ...newbuildingInfoForTgb,
          [parameters.objectId]: data.enrichments,
        };

        dispatch(setNewbuildingsInfoForTgb(payload));
      },
      /**
       * @description Не требуется особых действий, так как в сервисе залогировали поведение,
       * ретраи не требуются и в сама ошибка в сторе нам на данном этапе не требуется
       */
      noop,
    );
  };
}
